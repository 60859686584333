<template>
  <div class="mDownload">
    <div class="mDownload-top">
      <div class="mDownload-top-top">
        <p>述宠</p>
        <div></div>
        <p>科学养宠</p>
      </div>
      <div class="mDownload-top-bottom">
        <p>述宠是一款记录宠物日常生活习惯的APP，涵盖了铲屎 </p>
        <p>
          官的日常生活中常用的工具，还可记录宠物每日每月账
        </p>
        <p>
          单 让你对宠物的所有一目了然。
        </p>
      </div>

    </div>
    <div class="mDownload-bottom">
      <div class="mDownload-bottom-top">
        <div  class="mDownload-bottom-top-item" v-for="(item,index) in downloadList" :key="index" @click="downloadClick(item.title)">
          <img :src="item.img" alt="">
          <p>{{ item.title }}</p>
          <div v-if="downloadtitle==item.title" class="mDownload-bottom-top-line"></div>
        </div>
      </div>
      <div class="mDownload-bottom-bottom" v-if="downloadtitle!=''">
        <div class="mDownload-bottom-bottom-left">
          <img src="../../assets/home/MiniCode.png" v-if="downloadtitle=='小程序'" alt="">
          <!-- <img src="../../assets/home/code.png" v-else alt="" srcset=""> -->
          <img src="../../assets/home/index/androidDownLoadCode.png" v-else alt="" srcset="">
        </div>
        <div class="mDownload-bottom-bottom-right">
          <p>扫描二维码
          </p>
          <p v-if="downloadtitle=='小程序'">进入微信小程序</p>
          <p v-else>下载述宠app</p>
        </div>
      </div>

    </div>
    <div v-if="showBottom" class="mDownload-bottom-bottom1">
      <div class="mDownload-bottom-bottom-left">
        <!-- <img src="../../assets/home/code.png" alt="" srcset=""> -->
        <img src="../../assets/home/MiniCode.png" alt="">
      </div>
      <div class="mDownload-bottom-bottom-right">
        <div>
          <img src="../../assets/mi/download/wx.png" alt="">
          <p>小程序</p>
        </div>
        <p>扫描二维码进入
        </p>
        <p>述宠微信小程序
        </p>
      </div>
    </div>
    <div class="mDownload-phone">
      <img v-lazy="phone" alt="" srcset="">
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      phone: require('../../assets/mi/download/phone.png'),
      downloadtitle: '',
      showBottom: true,
      downloadList: [
        { img: require('../../assets/mi/download/wx.png'), title: '小程序' },
        { img: require('../../assets/mi/download/apple.png'), title: 'ios下载' },
        { img: require('../../assets/mi/download/android.png'), title: '安卓下载' },
      ],
    }
  },
  methods: {
    downloadClick(downloadtitle) {
      if (this.downloadtitle != downloadtitle) {
        this.downloadtitle = downloadtitle
        this.showBottom = false
      } else {
        this.downloadtitle = ''
        this.showBottom = true
      }
    },
  }
}
</script>
<style lang="scss" scoped>
.mDownload {
  background-image: url("../../assets/mi/download/bg.png");
  background-size: 100% 100%;
  width: 100%;
  position: relative;
  .mDownload-top {
    display: flex;
    flex-direction: column;
    align-items: center;
    .mDownload-top-top {
      display: flex;
      margin-bottom: 25px;
      margin-top: 49.5px;
      align-items: center;
      div {
        width: 4px;
        height: 30px;
        background-color: #fff;
        margin-left: 10px;
        margin-right: 10px;
      }
      p {
        font-weight: bold;
        font-size: 30px;
        color: #fff;
      }
    }
    .mDownload-top-bottom {
      width: 100%;
      padding-left: 26px;
      padding-right: 26px;
      box-sizing: border-box;
      text-align: center;
      margin-bottom: 35.5px;
      p {
        // font-weight: bold;
        font-size: 13px;
        color: #fff;
        font-family: "SourceHanSansCN-Medium";
      }
    }
  }
  .mDownload-bottom {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    box-sizing: border-box;

    .mDownload-bottom-top {
      background-color: #fff;
      width: 100%;
      height: 45px;
      border-radius: 22.5px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      box-shadow: 0px 4px 13px 3px rgba(123, 200, 210, 0.18);
      .mDownload-bottom-top-item {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 45px;
        position: relative;
        img {
          width: 22px;
          height: 22px;
        }
        p {
          font-family: "SourceHanSansCN-Medium";
          color: #7bc8d2;
          font-size: 13px;
          margin-left: 5px;
        }
        .mDownload-bottom-top-line {
          position: absolute;
          width: 100%;
          height: 3px;
          background-color: #7bc8d2;
          border-radius: 1px;
          bottom: 0px;
        }
      }
    }
    .mDownload-bottom-bottom {
      box-shadow: 0px 4px 13px 3px rgba(123, 200, 210, 0.18);
      background-color: #fff;
      width: 100%;
      height: 150px;
      border-radius: 10px;
      margin-top: 14.5px;
      display: flex;
      align-items: center;
      justify-content: center;
      .mDownload-bottom-bottom-left {
        margin-right: 15px;
        font-size: 0px;
        img {
          width: 117px;
          height: 117px;
          border-radius: 5px;
        }
      }
      .mDownload-bottom-bottom-right {
        text-align: center;
        p {
          font-family: SourceHanSansCN-Medium;
          color: #7BC8D2;
          font-size: 14px;
          letter-spacing: 2px;
        }
        // margin-bottom: 10px;
      }
    }
  }
  .mDownload-phone {
    margin-top: 49px;
    img {
      width: 322.35px;
      height: 381px;
    }
  }
}

.mDownload-bottom-bottom1 {
  margin-top: 25px;
  margin-left: 25px;
  margin-right: 25px;
  box-sizing: border-box;
  box-shadow: 0px 4px 13px 3px rgba(123, 200, 210, 0.18);
  background-color: #fff;
  // width: 100%;
  height: 150px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  .mDownload-bottom-bottom-left {
    margin-right: 15px;
    font-size: 0px;
    img {
      width: 117px;
      height: 117px;
      border-radius: 5px;
    }
  }
  .mDownload-bottom-bottom-right {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    div {
      display: flex;
      align-items: center;
      margin-bottom: 5px;
      img {
        width: 19px;
        height: 19px;
        margin-right: 6px;
      }
      p {
        font-size: 14px;
      }
    }
    p {
      font-family: SourceHanSansCN-Medium;
      color: #7BC8D2;
      font-size: 11px;
      letter-spacing: 2px;
    }
    // margin-bottom: 10px;
  }
}
</style>